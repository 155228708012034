<script setup>
import { useI18n } from 'vue-i18n'
import { useLocalStorage } from '~/composables/useLocalStorage'
// import { useAppwrite } from '@/services/appwrite'

const { locale } = useI18n()

// const { fetchUser } = useAppwrite()

useHead({
  title: 'Annotate Image - Free Online Image Annotator',
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    {
      name: 'description',
      content:
        'Annotate images online for free. Use our tool to add labels, arrows, and annotations to PNG, GIF, JPG, PDF, and SVG files. Start annotating now!',
    },
    {
      name: 'keywords',
      content:
        'annotate image tool, annotate image online, annotate image free, annotate image app, annotate image website, annotate image software, annotate image tool online, annotate image tool free, annotate image tool app, annotate image tool website, annotate image tool software, annotate image editor, annotate image editor online, annotate image editor free, annotate image editor app, annotate image editor website, annotate image editor software, annotate image maker, annotate image maker online, annotate image maker free, annotate image maker app, annotate image maker website, annotate image maker software, annotate image creator, annotate image creator online, annotate image creator free, annotate image creator app, annotate image creator website, annotate image creator software, annotate image generator, annotate image generator online, annotate image generator free, online annotation app, online annotations,free annotation tool',
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.ico',
    },
  ],
  script: [
    {
      src: 'https://cdn.jsdelivr.net/npm/codemirror@6.65.7/lib/codemirror.min.js',
      crossorigin: 'anonymous',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/codemirror@5.65.7/addon/mode/simple.min.js',
      crossorigin: 'anonymous',
    },
  ],
  link: [
    {
      rel: 'stylesheet',
      type: 'text/css',
      href: 'https://cdn.jsdelivr.net/npm/codemirror@5.65.7/theme/dracula.css',
    },
  ],
})

const theme = ref('light')

onMounted(() => {
  if (process.client) {
    const userLocale = useLocalStorage().get('locale')
    const userTheme = useLocalStorage().get('theme')
    const accessToken = useLocalStorage().get('accessToken')

    if (userTheme) theme.value = userTheme
    // if (accessToken) useUserStore().setAccessToken(accessToken)
    locale.value = userLocale || 'en'

    // fetchUser()
  }
})
</script>

<template>
  <Html :data-theme="theme">
    <Body class="bg-primary m-0 p-0 text-primary overflow-x-hidden">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </Body>
  </Html>
</template>

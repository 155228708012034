import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/build/repo/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "detect-domain": () => import("/opt/build/repo/middleware/detectDomain.ts")
}
import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as annotatorylzJwoVQyNMeta } from "/opt/build/repo/pages/app/annotator.vue?macro=true";
import { default as confirm_45account_45_91code_93egwyxpPILjMeta } from "/opt/build/repo/pages/app/confirm-account-[code].vue?macro=true";
import { default as convert_svgzrMxbNbkSzMeta } from "/opt/build/repo/pages/app/convert_svg.vue?macro=true";
import { default as convert_45svg_45to_45png2wGm5TwJskMeta } from "/opt/build/repo/pages/app/convert-svg-to-png.vue?macro=true";
import { default as forgot_45password0F4ZxbbHs8Meta } from "/opt/build/repo/pages/app/forgot-password.vue?macro=true";
import { default as profileFrxDQwpncFMeta } from "/opt/build/repo/pages/app/profile.vue?macro=true";
import { default as reset_45password_45_91token_93SqgLTE91N1Meta } from "/opt/build/repo/pages/app/reset-password-[token].vue?macro=true";
import { default as settingsMP7B1u343qMeta } from "/opt/build/repo/pages/app/settings.vue?macro=true";
import { default as signinIWitkfHlYOMeta } from "/opt/build/repo/pages/app/signin.vue?macro=true";
import { default as signuppgRMrR0K0aMeta } from "/opt/build/repo/pages/app/signup.vue?macro=true";
import { default as homeZXalWvqDQEMeta } from "/opt/build/repo/pages/home.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as optimize_45svg_45filesv90HoGSnZhMeta } from "/opt/build/repo/pages/tools/optimize-svg-files.vue?macro=true";
export default [
  {
    name: "About",
    path: "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "Annotator",
    path: "/app/annotator",
    meta: annotatorylzJwoVQyNMeta || {},
    component: () => import("/opt/build/repo/pages/app/annotator.vue").then(m => m.default || m)
  },
  {
    name: "confirm-account",
    path: "/app/confirm-account-:code()",
    meta: confirm_45account_45_91code_93egwyxpPILjMeta || {},
    component: () => import("/opt/build/repo/pages/app/confirm-account-[code].vue").then(m => m.default || m)
  },
  {
    name: "Optimize SVGs",
    path: "/app/convert_svg",
    meta: convert_svgzrMxbNbkSzMeta || {},
    component: () => import("/opt/build/repo/pages/app/convert_svg.vue").then(m => m.default || m)
  },
  {
    name: "app-convert-svg-to-png",
    path: "/app/convert-svg-to-png",
    component: () => import("/opt/build/repo/pages/app/convert-svg-to-png.vue").then(m => m.default || m)
  },
  {
    name: "ForgotPassword",
    path: "/app/forgot-password",
    meta: forgot_45password0F4ZxbbHs8Meta || {},
    component: () => import("/opt/build/repo/pages/app/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "Profile",
    path: "/app/profile",
    meta: profileFrxDQwpncFMeta || {},
    component: () => import("/opt/build/repo/pages/app/profile.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/app/reset-password-:token()",
    meta: reset_45password_45_91token_93SqgLTE91N1Meta || {},
    component: () => import("/opt/build/repo/pages/app/reset-password-[token].vue").then(m => m.default || m)
  },
  {
    name: "Settings",
    path: "/app/settings",
    meta: settingsMP7B1u343qMeta || {},
    component: () => import("/opt/build/repo/pages/app/settings.vue").then(m => m.default || m)
  },
  {
    name: "Login",
    path: "/app/signin",
    meta: signinIWitkfHlYOMeta || {},
    component: () => import("/opt/build/repo/pages/app/signin.vue").then(m => m.default || m)
  },
  {
    name: "Signup",
    path: "/app/signup",
    meta: signuppgRMrR0K0aMeta || {},
    component: () => import("/opt/build/repo/pages/app/signup.vue").then(m => m.default || m)
  },
  {
    name: "Home",
    path: "/",
    meta: homeZXalWvqDQEMeta || {},
    component: () => import("/opt/build/repo/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyjCuzG6sq6pMeta || {},
    component: () => import("/opt/build/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms9FLT7o4CI4Meta || {},
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "tools-optimize-svg-files",
    path: "/tools/optimize-svg-files",
    component: () => import("/opt/build/repo/pages/tools/optimize-svg-files.vue").then(m => m.default || m)
  }
]